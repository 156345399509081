import L from "leaflet";
import { useState, useEffect } from "react";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottom: "leaflet-bottom",
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    top: "leaflet-top leaflet-center",
};

type CoordinateControlProps = {
    position: string;
    latlng: L.LatLngLiteral
    onChange?: (latlng: L.LatLngLiteral) => void;
};

export default function CoordinateControl({ position, latlng, ...props }: CoordinateControlProps) {
    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;
    const [value, setValue] = useState<string>('')

    const onChange = (e) => {
        setValue(e.target.value)
        const [lat, lng] = e.target.value.split(',').map(parseFloat)
        const newLatLng = L.latLng(lat, lng)

        if (props.onChange && newLatLng)
            props.onChange(newLatLng)
    }

    const onClick = (e) => {
        console.log(e)
        e.preventDefault(e)
      }

    useEffect(() => {
        setValue(latlng ? `${latlng.lat.toLocaleString('en-US', {maximumFractionDigits: 6})},${latlng.lng.toLocaleString('en-US', {maximumFractionDigits: 6})}`: "")
    }, [latlng])

    return (
        <div className={`leaflet-control ${positionClass}`} onClick={onClick}>
            <div className="leaflet-control leaflet-bar" >
                <input style={{width: "200px", padding: "5px", textAlign: "center", fontSize: "18px", marginLeft: "-100px"}} type="text" id="coordinates" name="coordinates" onChange={onChange} placeholder={"52.37, 4.90"} value={value}></input>
            </div>
        </div>
    );   
}

