import { MapContainer, TileLayer, LayersControl, GeoJSON, ScaleControl, ZoomControl, Marker, Popup, useMapEvent} from 'react-leaflet'
import type { GeoJsonObject } from 'geojson'
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression, LatLngLiteral } from "leaflet";
import chroma from "chroma-js";
// import GeoRasterLayer from "./GeoRasterLayer";
import LegendControl from './LegendControl';
import LegendColorbar from './LegendColorbar';
import LogoControl from './LogoControl';
import logo from '../logo.svg';
import turbines from "./turbines.json";
import { useState } from 'react';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import CoordinateControl from './CoordinateControl';

// Set up the default icon for markers
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12.5, 41],
  popupAnchor: [0, -41],
});

L.Marker.prototype.options.icon = DefaultIcon;

const wind_speed_limits: [number, number] = [5, 10]
const wind_speed_deficit_limits: [number, number] = [-6, 6]

const colormap = (cmapName: string, vmin: number, vmax: number, scale: number, value: Array<number>) => {
  if (isNaN(value[0])) return "rgba(0, 0, 0, 0)";
  return colorscales[cmapName]((value[0] / scale - vmin) / (vmax - vmin)).alpha(0.8).css();
};

export const colorscales = {
  greys: chroma.scale("Greys").domain([1, 0]),
  viridis: chroma.scale("Viridis"),
  RdBu: chroma.scale("RdBu"),
};

const setIcon = ({ properties }, latlng) => {
  return L.circleMarker(latlng)
};

type LocationPopupProps = {
  latlng: LatLngExpression;
  onChange?: (latlng: LatLngLiteral) => void
};

function LocationPopup({ latlng, onChange }: LocationPopupProps) {
  // if (latlng) setLocation(L.latLng(latlng) as LatLngLiteral)
  const location = L.latLng(latlng) as LatLngLiteral
  const handleClick = (e) => {
    onChange(L.latLng(e.latlng))
    console.log(e)
  }
  useMapEvent('click', handleClick)
  return (
    (location ?
      <Popup position={location}>
        <h3>{location.lat.toFixed(6)}, {location.lng.toFixed(6)}</h3>
        <p>
          Download Whiffle Mesoscale data:
          <a href={`https://titiler.blue.development.whiffle.cloud/zarr/point.nc?url=gs%3A%2F%2Fwhiffle-meso-data-development%2Fminnesota_2012_windspeed_utm.zarr&variable=wind_speed&latlon=${location.lat},${location.lng}`}>
          NetCDF
          </a>, 
          <a href={`https://titiler.blue.development.whiffle.cloud/zarr/point.csv?url=gs%3A%2F%2Fwhiffle-meso-data-development%2Fminnesota_2012_windspeed_utm.zarr&variable=wind_speed&latlon=${location.lat},${location.lng}`}>
          CSV
          </a>
        </p>
        <p>
          Download ERA5 data: 
          <a href={`https://titiler.blue.development.whiffle.cloud/zarr/point.nc?url=gs://whiffle-reanalysis-data-production/era5/2024/era5-2024.zarr&variable=northward_wind_at_height&latlon=${location.lat},${location.lng}`}>
          NetCDF
          </a>, 
          <a href={`https://titiler.blue.development.whiffle.cloud/zarr/point.csv?url=gs://whiffle-reanalysis-data-production/era5/2024/era5-2024.zarr&variable=northward_wind_at_height&latlon=${location.lat},${location.lng}`}>
          CSV
          </a>
        </p>
      </Popup> : null)
  );
}




export default function Map() {
  const [latLng, setLatLng] =useState<LatLngLiteral>()
  return <>
      <CoordinateControl position="top" latlng={latLng} onChange={setLatLng} />
      <LogoControl position="topleft" logo={logo} />
      <MapContainer center={[46, -92]} style={{ height: "100vh" }} zoom={6} zoomControl={false}>
        <ScaleControl position="bottomleft" />
        <ZoomControl position="bottomleft" />
        <LocationPopup latlng={latLng} onChange={setLatLng} />
        <LegendControl position="bottomright" >
          <LegendColorbar
            limits={wind_speed_limits}
            unit="m/s"
            pixelValuesToColorFn={(v) => colormap("viridis", wind_speed_limits[0], wind_speed_limits[1], 1., [v as any])}
          />
        </LegendControl>
        <LayersControl position="topright">
          <LayersControl.BaseLayer  name="ESRI World Imagery" checked>
            <TileLayer
              attribution="Powered by <a href='https://www.esri.com'>Esri</a> | DigitalGlobe, GeoEye, i-cubed, USDA, USGS, AEX, <br/> Getmapping, Aerogrid, IGN, IGP, swisstopo, and the GIS User Community"
              url={`https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}?token=${process.env.NEXT_PUBLIC_ESRI_TOKEN}`}
            />
          </LayersControl.BaseLayer >
          <LayersControl.BaseLayer  name="OpenStreetMap">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer >
          <LayersControl.Overlay name="Minnesota" checked>
            <TileLayer
                url={`https://titiler.blue.development.whiffle.cloud/zarr/tiles/{z}/{x}/{y}?url=gs%3A%2F%2Fwhiffle-meso-data-development%2Fminnesota_2012_windspeed_utm.zarr&variable=wind_speed_mean&rescale=${wind_speed_limits[0]},${wind_speed_limits[1]}&colormap_name=viridis`}
              />
          </LayersControl.Overlay>
          <LayersControl.Overlay name="3DEP" checked>
            <TileLayer
                url={`https://titiler.blue.development.whiffle.cloud/zarr/tiles/{z}/{x}/{y}?url=gs%3A%2F%2Fwhiffle-meso-data-development%2Fminnesota_2012_windspeed_utm.zarr&variable=wind_speed_mean&rescale=${wind_speed_limits[0]},${wind_speed_limits[1]}&colormap_name=viridis`}
              />
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
    </>
}