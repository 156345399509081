// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottom: "leaflet-bottom",
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    top: "leaflet-top",
};

type LegendControlProps = {
    position: string;
    logo: string
};

export default function LogoControl({ position, logo, ...props }: LegendControlProps) {
    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    return (
        <div className={positionClass}>
            <div className="leaflet-control leaflet-bar" >
                <a href="https://whiffle.nl/" style={{"width": "150px", "padding": "5px", "paddingBottom": "10px"}}>
                    <img src={logo} alt="Whiffle Logo"  />
                </a>
            </div>
        </div>
    );   
}

