import { ReactNode, useEffect, useRef } from "react";
import L from "leaflet";

// Classes used by Leaflet to position controls
const POSITION_CLASSES = {
    bottom: "leaflet-bottom",
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
    top: "leaflet-top leaflet-center",
};

type LegendControlProps = {
    position: string;
    children?: ReactNode[]|ReactNode;
};

/*************  ✨ Codeium Command ⭐  *************/
/******  fc350915-6076-4c4d-85b8-e25946a8f009  *******/

function LegendControl({ position, ...props }: LegendControlProps) {
    const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            L.DomEvent.disableClickPropagation(ref.current);
        }
    });

    return props?.children ? (
        <div className={positionClass} ref={ref}>
            <div className="leaflet-control leaflet-bar" style={{background: "white", marginBottom: "50px"}}>{props?.children}</div>
        </div>
    ) : null;
}

export default LegendControl;
